export const noteforusers =
  "All fields are optional. It is good to fill all relevant fields to get accurate results. Use simple and minimum inputs - TipsyTom will do the drafting for you. The knowledge content, tone, style and drafting of exemption application in this Scripter is for use in Indian courts.  ";


export const formdata = [
  {
    name: "Section 1: Details of Complaint",
    group: [
      {
        name: "Before the Court of the:",
        placeholder: "LD CMM, Pune",
        id: "court_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Name of Complainant:",
        placeholder: "Devendra Sharma",
        id: "complainant_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Court Case Number: ",
        placeholder: "CC No. [] of []",
        id: "case_number",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
    ],
  },
  {
    name: "Section 2: Details of the Accused",
    group: [
      {
        name: "Name of Accused:",
        placeholder: "Hari Sharma",
        id: "accused_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Accused No.:",
        placeholder: "5",
        id: "accused_no",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Gender of accused:",
        placeholder: "Male/Female",
        id: "gender_switch",
        type: "input-select",
          group: [
          {
            name: "Male",
            element: "input-select",
            nameAtt: "Male/Female",
            id: "MaleGenderComplainant",
            type: "input-select",
          },
          {
            name: "Female",
            element: "input-select",
            type: "input-select",
            nameAtt: "Male/Female",
            id: "FemaleGenderComplainant",
          },
        ],
      },
      {
        name: "If the accused is a company/ body corporate? Yes/ No:",
        placeholder: "Yes",
        id: "company_question",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
   
    ],
  },
  {
    name: "Section 3: Exemption Sought",
    group: [
      {
        name: "Main reasons for absence of the accused: ",
        placeholder: "Mother’s illness",
        id: "reasons_absence_1",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Additional reasons, if any:",
        placeholder: "Traveling to Delhi with mother for her medical treatment",
        id: "additional_grounds",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Supporting document(s): ",
        placeholder: " Medical certificate of the mother ",
        id: "supporting_doc_1",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "Hearings for which exemption sought:",
        group: [
          {
            name: "Every Hearing",
            element: "input-radio",
            type: "radio",
            nameAtt: "hearingdateexemptions",
            id: "EveryHearing",
          },
          {
            name: "Select Dates:",
            element: "input-radio",
            type: "radio",
            nameAtt: "hearingdateexemptions",
            id: "SelectDates",

          },
          {
            // name: "Other",
            placeholder: "Select the hearing dates for which exemption is seeked",
            element: "dateselect",
            type: "date-select",
            // id: "oth",
            id: "INPUT_EXEMPTIONDATES",
          },
        ],
      },
      {
        name: "Place of hearing: ",
        placeholder: "Pune",
        id: "place_of_hearing",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      
{        name: "Is accused place of residence same as place of hearing",
        placeholder: "Yes/No",
        id: "residence_same_as_hearing_place",
        type:"input-select",
        group: [
          {
            name: "No",
            element: "input-select",
            type: "input-select",
            nameAtt: "place of residence same as place of hearing",
            id: "Noplaceofhearing",
          },
          {
            name: "Yes",
            element: "input-select",
            nameAtt: "place of residence same as place of hearing",
            id: "Yesplaceofhearing",
            type: "input-select",
          },
       
        ],
      
    
      },
      {
            name: "Accused place of residence:",
            placeholder: "Raigarh",
            id: "accused_residence_city",
            type: "text",
            element: "input-text",
            required: false,
            optional: true,
          },
      {
        name: " Date of exemption application: ",
        placeholder: " 30/10/2023",
        id: "date",
        type: "date",
        element: "input-text",
        required: false,
        optional: true,
      },
    ],
  },
  {
    name: "Section 4: Details of Advocate",
    group: [
      {
        name: "Name:",
        placeholder: "Ismail Khan",
        id: "advocate_name",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
      {
        name: "City:",
        placeholder: "Pune",
        id: "advocate_city",
        type: "text",
        element: "input-text",
        required: false,
        optional: true,
      },
    ],
  },
];

export const fieldNames = [
  "complainant_name",
  "court_name",
  "accused_name",
  "case_number",
  "accused_no",
  "additional_grounds",
  "accused_no",
  "gender_switch",
  "company_question",
  "residence_same_as_hearing_place",
  "accused_residence_city",
  "reasons_absence_1",
  // "reasons_absence_2",
  "supporting_doc_1",
  // "supporting_doc_2",
  "place_of_hearing",
  "date",
  "advocate_name",
  "advocate_city",
];

