import React, { useState } from 'react';
import "../css/popup.css"

const NoteForUser = ({ note, onConfirm }) => {
    const [showPopup, setShowPopup] = useState(true);

    const handleConfirm = () => {
        setShowPopup(false);
        onConfirm();
    };

    const handleCancel = () => {
        setShowPopup(false);
        onConfirm();
    };
    return (
        <>
            {showPopup && (
                <div className="popup-overlay alert alert-secondary" role="alert">
                    <div style={{width:"700px"}} className="popup-container p-5 position-relative">
                        <i onClick={handleCancel} className='far fa-window-close fa-2x position-absolute top-2 right-5 cursor-pointer'></i>
                        <p className="popup-message my-2 text-secondary float-start"><b>Note for users : </b>{note}</p>
                        <div className='text-center mt-5'>
                            <button
                                onClick={handleConfirm}
                                className='btn btn-primary mt-3 rounded-xl'>Proceed</button>
                        </div>
                    </div>
                </div >
            )}
        </>
    );
};

export default NoteForUser;