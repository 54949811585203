import React from 'react'
import { Link } from 'react-router-dom'
const Footer = () => {
    const support = () => {
        const recipientAddress = 'talk@tipsytom.com';
        const subjectLine = 'TipsyTom | Support Query';
        const mailtoUrl = `mailto:${recipientAddress}?subject=${encodeURIComponent(subjectLine)}`;
        const mailLink = document.createElement('a');
        mailLink.href = mailtoUrl;
        mailLink.click();
    }



    return (
        <div style={{ fontWeight: "500",}} className='text-center bg-[#F4F5FA] custom-footer'>
            <br />
            <h3 className="my-4">Tipsy Tom</h3>
            {/* <p>
                <i className="fab fa-facebook fa-2x mx-4"></i>
                <i className="fab fa-twitter fa-2x mx-4"></i>
                <i className="fab fa-instagram fa-2x mx-4"></i>
            </p> */}
            <p onClick={() => support()} className='my-3 cursor-pointer'><b>Contact Us <i className="fas fa-envelope"></i> :
            </b> talk@tipsytom.com</p>
            <p className='d-flex justify-content-center'>
                <Link style={{ color: "#000000" }} className="nav-link" to="/privacy-policy">
                    <span className='underline-nav'>Privacy Policy</span>
                </Link>
                <Link style={{ color: "#000000" }} className="nav-link" to="/terms-of-service">
                    <span className='underline-nav'>Terms of service</span>
                </Link>
            </p>
            <p className=''>All rights @Lipi Labs. Doing AI right.</p>
            <br />
            <br />
        </div >
    )
}

export default Footer