import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
// import 'tailwindcss/tailwind.css';
import { BrowserRouter, Route, Routes, json } from "react-router-dom";
import RenderOnAnonymous from "./KC-Auth/RenderOnAnonymous";
import RenderOnAuthenticated from "./KC-Auth/RenderOnAuthenticated";

// import NavBar from './components/NavBar';
import Home from "./components/tailwindComponents/Home";
import ErrorPage from "./components/ErrorPage";
import "./components/css/style.css";
import { useEffect, useState } from "react";
import UserService from "./services/UserService";

import { ReactNotifications } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import NavBar from "./components/tailwindComponents/NavBar";
import Subscription from "./components/tailwindComponents/Subscription";
import PaymentSuccess from "./components/tailwindComponents/PaymentSuccess";
import PaymentFailed from "./components/tailwindComponents/Payment Component/PaymentFailed";
import UserLibrary from "./components/tailwindComponents/TailUserLibrary";
import LegalNotice from "./components/tailwindComponents/LegalNotice";
import DraftAgreement from "./components/tailwindComponents/DraftAgreement";
// import Upcoming from './components/Upcoming';
import axios from "axios";

import 'spiketip-tooltip/spiketip.min.css'

import { v4 as uuidv4 } from 'uuid';
import SignUp from "./components/tailwindComponents/SignUp";
import LandingPage from "./components/tailwindComponents/LandingPage"
import { useDispatch, useSelector } from "react-redux";
import EmploymentAgreement from "./components/tailwindComponents/EmploymentAgreement";
import NonDisclosureAgreement from "./components/tailwindComponents/NonDisclosureAgreement";
import LeaseAgreement from "./components/tailwindComponents/LeaseAgreement";
import ServiceAgreement from "./components/tailwindComponents/ServiceAgreement";
import CustomisedTemplate from "./components/tailwindComponents/CustomisedTemplate";
import TermOfServices from "./components/tailwindComponents/TermOfServices"
import PrivacyPolicy from "./components/tailwindComponents/PrivacyPolicy";

import SupplyAgreement from "./components/tailwindComponents/SupplyAgreement";
import IpLicensingAgreement from "./components/tailwindComponents/IpLicensingAgreement";
import IpAssignmentAgreement from "./components/tailwindComponents/IpAssignmentAgreement"
import PowerEditor from "./components/tailwindComponents/PowerEditor";
import AutoAgreement from "./components/tailwindComponents/AutoAgreement"
import MySubscriptionDetails from "./components/tailwindComponents/MySubscriptionDetails";
import Footer from "./components/Footer";
import ContactUs from "./components/tailwindComponents/ContactUS";
import { getCheckPricing } from "./Redux/Action/action";
import BailApplication from "./components/tailwindComponents/BailApplication";
import AnticipatoryBailApplication from "./components/tailwindComponents/AnticipatoryBailApplication";
import ExemptionApplication from "./components/tailwindComponents/ExemptionApplication";

const { REACT_APP_CREATE_AGREEMENT_API } = process.env
function App() {
  const state = useSelector((state) => state.stateReducer);
  const dispatch = useDispatch()
  useEffect(() => {
    setUnAuthenticated()
    if (UserService.isLoggedIn()) setUserStatus()
  }, []);
  const checkTimeOf1Hour = (value) => {
    const dateTimeString = value;
    const dateTime = new Date(dateTimeString);
    const targetDateTime = new Date(dateTime.getTime() + 60 * 60 * 1000); // Adding 1 hour in milliseconds
    const currentDateTime = new Date();
    if (currentDateTime >= targetDateTime) return 'over'
    else return 'not-over'

  }
  const setUserStatus = () => {
    const userID = UserService.getUserId()
    const userStatusObjFromStorage = JSON.parse(localStorage.getItem(userID + "StatusKeySubscriptions"))

    if (userStatusObjFromStorage) {
      if (checkTimeOf1Hour(userStatusObjFromStorage.userStatusTime) === "not-over") {
        localStorage.removeItem(userID + "StatusKeySubscriptions");
        getListOfUserSubscription()
      }
    }
    else getListOfUserSubscription()
  }

  const getListOfUserSubscription = async () => {
    if (UserService.isLoggedIn()) {
      try {
        const FormData = require("form-data");
        const sendData = new FormData();
        sendData.append("keycloak_user_id", UserService.getUserId());

        const config = {
          method: "post",
          url: `${REACT_APP_CREATE_AGREEMENT_API}/list_active_user_subscriptions`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "access_token": UserService.getToken()
          },
          data: sendData,
        };
        const getResponse = await axios(config);
        if (getResponse.status === 200) {

          const isCurrentAvailabe = (value) => {
            let temp;
            value.forEach((ele) => {
              if (ele.current_status === "active") temp = true
            })
            if (temp === true) {
              dispatch(getCheckPricing(false))
              return true
            }
            else {
              dispatch(getCheckPricing(true))
              return false
            }
          }

          const userID = UserService.getUserId()
          const tempObj = {
            navbarCheck: {
              current: isCurrentAvailabe(getResponse.data)
            }
          }
          localStorage.setItem(userID + "StatusKeySubscriptions", JSON.stringify(tempObj))
        }
      } catch (err) {
        console.log("Error", err);
      }
    }
  }
  const getIp = async () => {
    const config = {
      method: "get",
      url: `${REACT_APP_CREATE_AGREEMENT_API}/get_ip`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "access_token": UserService.getToken()
      },
    }

    try {
      const getResponse = await axios(config)
      if (getResponse.status === 200) {
        return getResponse.data
        // console.log(getResponse.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const dateChecker = (value) => {
    // Example date and time
    const dateTimeString = value;

    // Create a Date object from the provided date and time string
    const dateTime = new Date(dateTimeString);

    // Calculate the target date and time which is 24 hours in the past from the provided date and time
    const targetDateTime = new Date(dateTime.getTime() + 60 * 60 * 1000); // Adding 1 hour in milliseconds

    // Get the current date and time
    const currentDateTime = new Date();

    // Compare the current date and time with the target date and time
    if (currentDateTime >= targetDateTime) return 'over'
    else return 'not-over'

  }

  const createUnAuthData = async () => {
    const id = uuidv4()
    const value = 6
    const time = new Date()
    const ip = await getIp()
    const dic = {
      id: id,
      value: value,
      time: time,
      ip: ip,
      // track: { NDAApi: [], EmploymentApi: [] }
    }
    localStorage.setItem("UnAuthkey", JSON.stringify(dic))
  }

  const setUnAuthenticated = async () => {
    const key = JSON.parse(localStorage.getItem("UnAuthkey"))
    if (key) {
      if (dateChecker(key.time) === "over") {
        localStorage.removeItem('UnAuthkey');
        createUnAuthData()
      }
    }
    else {
      createUnAuthData()
    }
  }

  return (
    <BrowserRouter>
      <RenderOnAnonymous>
        <NavBar />
        <ReactNotifications />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/terms-of-service" element={<TermOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/app" element={<Home />} />
          <Route path="/app/subscription" element={<Subscription />} />
          <Route path="/app/employment-agreement" element={<EmploymentAgreement />} />
          <Route path="/app/non-disclosure-agreement" element={<NonDisclosureAgreement />} />
          <Route path="/app/customised-template" element={<CustomisedTemplate />} />
          <Route path="/app/contact-us" element={<ContactUs />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
        <NavBar />
        <ReactNotifications />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/terms-of-service" element={<TermOfServices />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/app" element={<Home />} />
          <Route path="/app/subscription" element={<Subscription />} />
          <Route path="/app/contact-us" element={<ContactUs />} />
          <Route path="/app/my-subscriptions" element={<MySubscriptionDetails />} />
          <Route path="/app/payment_success" element={<PaymentSuccess />} />
          <Route path="/app/payment_failed" element={<PaymentFailed />} />
          <Route path="/app/draft-agreement" element={<DraftAgreement />} />
          <Route path="/app/lease-agreement" element={<LeaseAgreement />} />
          <Route path="/app/power-editor" element={<PowerEditor />} />
          <Route path="/app/service-agreement" element={<ServiceAgreement />} />
          <Route path="/app/legal-notice" element={<LegalNotice />} />
          <Route path="/app/employment-agreement" element={<EmploymentAgreement />} />
          <Route path="/app/non-disclosure-agreement" element={<NonDisclosureAgreement />} />
          <Route path="/app/supply-agreement" element={<SupplyAgreement />} />
          <Route path="/app/ip-licensing-agreement" element={<IpLicensingAgreement />} />
          <Route path="/app/ip-assignment-agreement" element={<IpAssignmentAgreement />} />
          <Route path="/app/customised-template" element={<CustomisedTemplate />} />
          <Route path="/app/bail-application" element={<BailApplication />} />
          <Route path="/app/anticipatory-bail-application" element={<AnticipatoryBailApplication />} />
          <Route path="/app/exemption-application-from-pa" element={<ExemptionApplication />} />
          <Route path="/app/library" element={<UserLibrary />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </RenderOnAuthenticated>
    </BrowserRouter>
  );
}

export default App;
